
$green: #69be28;
$orange: #fca400;
$red: #e2071b;

$blue: #063D66;
$beige: #f5ead4;
$beige-dark: #B29000;

$primary: #063D66;
$primary-light: lighten(#063D66, 15%);
$primary-dark: #001728;
$primary-inverted: #fff;

$secondary: white;
$secondary-light: #F0F1F2;
$secondary-dark: #B29000;

$white: #fff;
$darkGray: #2d2e2b;
$rowBack: #f0f0f0;
$altRowBack: #e8e8e8;
$default: #ebebeb;

$tableHead: #B29000;
$tableOdd: #f0eeec;
$tableEven: #e1ddd9;

.padding {
    padding:0.1em;
}